import React from 'react';
import { Field } from 'react-final-form';

const FieldDropdownSelect = ({ name, options, placeholder, setType, validate }) => {
  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => (
        <div>
          <select
            value={input.value}
            onChange={e => {
              input.onChange(e.target.value);
              setType(e.target.value);
            }}
          >
            <option value="" disabled>
              {placeholder}
            </option>
            {options.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {meta.touched && meta.error && <span>{meta.error}</span>}{' '}
          {/* Display error message if any */}
        </div>
      )}
    </Field>
  );
};

export default FieldDropdownSelect;
