import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Promised } from '../../components';
import { readImage } from '../../components/ImageFromS3/ImageFromS3';
import css from './ListingPage.module.css';
import moment from 'moment';

const SectionProjectMaybe = props => {
  const { publicData } = props;
  const { projects } = publicData || {};

  return (
    <div>
      {projects && Array.isArray(projects) && projects.length > 0 ? (
        <div className={css.projectContent}>
          <div className={css.projectTopic}>
            <FormattedMessage id="ListingPageCarousel.project" />
          </div>
          {projects.map(project => {
            const title = project?.title ?? null;
            const startDate = project?.startDate
              ? moment(project?.startDate).format('DD/MM/YYYY')
              : null;
            const endDate = project?.endDate ? moment(project?.endDate).format('DD/MM/YYYY') : null;
            const location = project?.location ? project?.location?.selectedPlace.address : null;
            const details = project?.details ? project?.details : null;
            const photos = project?.projectPhotos ? project?.projectPhotos : null;

            return (
              <div className={css.serviceItem} key={project.id}>
                <div>
                  {title ? <div className={css.projectTitle}>{title}</div> : null}
                  {startDate && endDate ? (
                    <div className={css.projectDate}>
                      {startDate} - {endDate}
                    </div>
                  ) : null}
                  {location ? <div className={css.projectLocation}>{location}</div> : null}
                </div>
                {details ? <div className={css.projectDetails}>{details}</div> : null}
                {photos ? (
                  <div className={classNames(css.displayFlex, css.setOverFlow)}>
                    {photos.map(photo => {
                      //   if (typeof photo === 'string') {
                      return (
                        <Promised
                          key={photo}
                          promise={readImage(photo)}
                          renderFulfilled={dataURL => {
                            return <img src={dataURL} alt={photo} className={css.thumbImage} />;
                          }}
                          renderRejected={() => (
                            <div className={classes}>
                              <FormattedMessage id="ImageFromFile.couldNotReadFile" />
                            </div>
                          )}
                        />
                      );
                    })}
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default SectionProjectMaybe;
