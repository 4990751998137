import classNames from 'classnames';
import { arrayOf, bool, func, oneOfType } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { useConfiguration } from '../../context/configurationContext';
import {
  isErrorNoViewingPermission,
  isErrorUserPendingApproval,
  isForbiddenError,
  isNotFoundError,
} from '../../util/errors';
import { pickCustomFieldProps } from '../../util/fieldHelpers';
import { FormattedMessage, useIntl } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import {
  REVIEW_TYPE_OF_CUSTOMER,
  REVIEW_TYPE_OF_PROVIDER,
  SCHEMA_TYPE_MULTI_ENUM,
  SCHEMA_TYPE_TEXT,
  SCHEMA_TYPE_YOUTUBE,
  propTypes,
} from '../../util/types';
import {
  NO_ACCESS_PAGE_USER_PENDING_APPROVAL,
  NO_ACCESS_PAGE_VIEW_LISTINGS,
  PROFILE_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { hasPermissionToViewData, isUserAuthorized } from '../../util/userHelpers';

import {
  AvatarLarge,
  ButtonTabNavHorizontal,
  H2,
  H4,
  Heading,
  LayoutSideNavigation,
  ListingCard,
  Modal,
  NamedLink,
  NamedRedirect,
  Page,
  Reviews,
} from '../../components';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';

import FooterContainer from '../../containers/FooterContainer/FooterContainer';
import NotFoundPage from '../../containers/NotFoundPage/NotFoundPage';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Globe from '../../assets/globe.png';
import Location from '../../assets/location.png';
import PaperPlane from '../../assets/paperPlane.png';
import CustomAvailability from '../../components/CustomAvailability/CustomAvailability';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import InquiryForm from '../ListingPage/InquiryForm/InquiryForm';
import { handleSubmitInquiry } from '../ListingPage/ListingPage.shared';
import { sendInquiryinProfilePage } from './ProfilePage.duck';
import css from './ProfilePage.module.css';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';
import SectionTextMaybe from './SectionTextMaybe';
import SectionYoutubeVideoMaybe from './SectionYoutubeVideoMaybe';

const MAX_MOBILE_SCREEN_WIDTH = 768;
export const MIN_LENGTH_FOR_LONG_WORDS = 20;

export const AsideContent = props => {
  const { user, displayName, showLinkToProfileSettingsPage } = props;
  return (
    <div className={css.asideContent}>
      <AvatarLarge className={css.avatar} user={user} disableProfileLink />
      <H2 as="h1" className={css.mobileHeading}>
        {displayName ? (
          <FormattedMessage id="ProfilePage.mobileHeading" values={{ name: displayName }} />
        ) : null}
      </H2>
      {showLinkToProfileSettingsPage ? (
        <>
          <NamedLink className={css.editLinkMobile} name="ProfileSettingsPage">
            <FormattedMessage id="ProfilePage.editProfileLinkMobile" />
          </NamedLink>
          <NamedLink className={css.editLinkDesktop} name="ProfileSettingsPage">
            <FormattedMessage id="ProfilePage.editProfileLinkDesktop" />
          </NamedLink>
        </>
      ) : null}
    </div>
  );
};

export const ReviewsErrorMaybe = props => {
  const { queryReviewsError } = props;
  return queryReviewsError ? (
    <p className={css.error}>
      <FormattedMessage id="ProfilePage.loadingReviewsFailed" />
    </p>
  ) : null;
};

export const MobileReviews = props => {
  const { reviews, queryReviewsError } = props;
  const reviewsOfProvider = reviews.filter(r => r.attributes.type === REVIEW_TYPE_OF_PROVIDER);
  const reviewsOfCustomer = reviews.filter(r => r.attributes.type === REVIEW_TYPE_OF_CUSTOMER);
  return (
    <div className={css.mobileReviews}>
      <H4 as="h2" className={css.mobileReviewsTitle}>
        <FormattedMessage
          id="ProfilePage.reviewsFromMyCustomersTitle"
          values={{ count: reviewsOfProvider.length }}
        />
      </H4>
      <ReviewsErrorMaybe queryReviewsError={queryReviewsError} />
      <Reviews reviews={reviewsOfProvider} />
      <H4 as="h2" className={css.mobileReviewsTitle}>
        <FormattedMessage
          id="ProfilePage.reviewsAsACustomerTitle"
          values={{ count: reviewsOfCustomer.length }}
        />
      </H4>
      <ReviewsErrorMaybe queryReviewsError={queryReviewsError} />
      <Reviews reviews={reviewsOfCustomer} />
    </div>
  );
};

export const DesktopReviews = props => {
  const [showReviewsType, setShowReviewsType] = useState(REVIEW_TYPE_OF_PROVIDER);
  const { reviews, queryReviewsError } = props;
  const reviewsOfProvider = reviews.filter(r => r.attributes.type === REVIEW_TYPE_OF_PROVIDER);
  const reviewsOfCustomer = reviews.filter(r => r.attributes.type === REVIEW_TYPE_OF_CUSTOMER);
  const isReviewTypeProviderSelected = showReviewsType === REVIEW_TYPE_OF_PROVIDER;
  const isReviewTypeCustomerSelected = showReviewsType === REVIEW_TYPE_OF_CUSTOMER;
  const desktopReviewTabs = [
    {
      text: (
        <Heading as="h3" rootClassName={css.desktopReviewsTitle}>
          <FormattedMessage
            id="ProfilePage.reviewsFromMyCustomersTitle"
            values={{ count: reviewsOfProvider.length }}
          />
        </Heading>
      ),
      selected: isReviewTypeProviderSelected,
      onClick: () => setShowReviewsType(REVIEW_TYPE_OF_PROVIDER),
    },
    {
      text: (
        <Heading as="h3" rootClassName={css.desktopReviewsTitle}>
          <FormattedMessage
            id="ProfilePage.reviewsAsACustomerTitle"
            values={{ count: reviewsOfCustomer.length }}
          />
        </Heading>
      ),
      selected: isReviewTypeCustomerSelected,
      onClick: () => setShowReviewsType(REVIEW_TYPE_OF_CUSTOMER),
    },
  ];

  return (
    <div className={css.desktopReviews}>
      <div className={css.desktopReviewsWrapper}>
        <ButtonTabNavHorizontal className={css.desktopReviewsTabNav} tabs={desktopReviewTabs} />

        <ReviewsErrorMaybe queryReviewsError={queryReviewsError} />

        {isReviewTypeProviderSelected ? (
          <Reviews reviews={reviewsOfProvider} />
        ) : (
          <Reviews reviews={reviewsOfCustomer} />
        )}
      </div>
    </div>
  );
};

export const CustomUserFields = props => {
  const { publicData, metadata, userFieldConfig } = props;

  const shouldPickUserField = fieldConfig => fieldConfig?.showConfig?.displayInProfile !== false;
  const propsForCustomFields =
    pickCustomFieldProps(publicData, metadata, userFieldConfig, 'userType', shouldPickUserField) ||
    [];

  return (
    <>
      <SectionDetailsMaybe {...props} />
      {propsForCustomFields.map(customFieldProps => {
        const { schemaType, ...fieldProps } = customFieldProps;
        return schemaType === SCHEMA_TYPE_MULTI_ENUM ? (
          <SectionMultiEnumMaybe {...fieldProps} />
        ) : schemaType === SCHEMA_TYPE_TEXT ? (
          <SectionTextMaybe {...fieldProps} />
        ) : schemaType === SCHEMA_TYPE_YOUTUBE ? (
          <SectionYoutubeVideoMaybe {...fieldProps} />
        ) : null;
      })}
    </>
  );
};

export const MainContent = props => {
  const {
    userShowError,
    bio,
    displayName,
    listings,
    queryListingsError,
    reviews,
    queryReviewsError,
    publicData,
    metadata,
    userFieldConfig,
    intl,
    hideReviews,
    onManageDisableScrolling,
    onSendInquiry,
    getListing,
    sendInquiryInProgress,
    sendInquiryError,
    currentUser,
  } = props;

  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();
  const [inquiryModalOpen, setInquiryModalOpen] = useState(false);
  const [params, setParams] = useState('');
  const [listingTitle, setListingTitle] = useState('');
  const [authorName, setAuthorName] = useState('null');

  const {
    userType,
    companyName,
    companyContactNumber,
    name,
    title,
    contactNumber,
    defaultAvailability = {},
    language = [],
    availableToTravel = '',
    internationLicense = '',
    location = {},
    exceptions = [],
  } = publicData;

  const hasListings = listings.length > 0;
  const hasMatchMedia = typeof window !== 'undefined' && window?.matchMedia;
  const isMobileLayout = hasMatchMedia
    ? window.matchMedia(`(max-width: ${MAX_MOBILE_SCREEN_WIDTH}px)`)?.matches
    : true;

  const hasBio = !!bio;
  const bioWithLinks = richText(bio, {
    linkify: true,
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    longWordClass: css.longWord,
  });

  const listingsContainerClasses = classNames(css.listingsContainer, {
    [css.withBioMissingAbove]: !hasBio,
  });

  if (userShowError || queryListingsError) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProfilePage.loadingDataFailed" />
      </p>
    );
  }

  const commonParams = { params, history, routes: routeConfiguration };

  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  });

  return (
    <div>
      {userType !== 'corporate' ? (
        <H2 as="h1" className={css.desktopHeading}>
          <FormattedMessage id="ProfilePage.desktopHeading" values={{ name: displayName }} />
        </H2>
      ) : null}

      {userType === 'corporate' ? (
        <div>
          <H2 as="h1" className={css.companyContent}>
            {companyName}
          </H2>
          <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
            >
              <path
                d="M13 15.1667C13.2652 15.1667 13.5196 15.0613 13.7071 14.8738C13.8947 14.6862 14 14.4319 14 14.1667V11.8333C14 11.5681 13.8947 11.3138 13.7071 11.1262C13.5196 10.9387 13.2652 10.8333 13 10.8333C12.22 10.8333 11.4533 10.7133 10.72 10.4667C10.5447 10.4113 10.3575 10.4051 10.1789 10.4485C10.0003 10.492 9.83695 10.5836 9.70668 10.7133L8.74668 11.6733C7.08817 10.7683 5.72507 9.40517 4.82001 7.74667L5.77334 6.79333C6.04668 6.53333 6.14668 6.14667 6.02668 5.77333C5.78668 5.04667 5.66668 4.28 5.66668 3.5C5.66668 3.23478 5.56132 2.98043 5.37378 2.79289C5.18625 2.60536 4.93189 2.5 4.66668 2.5H2.33334C2.06813 2.5 1.81377 2.60536 1.62624 2.79289C1.4387 2.98043 1.33334 3.23478 1.33334 3.5C1.33334 9.93333 6.56668 15.1667 13 15.1667ZM2.33334 3.16667H4.66668C4.75508 3.16667 4.83987 3.20179 4.90238 3.2643C4.96489 3.32681 5.00001 3.41159 5.00001 3.5C5.00001 4.35333 5.13334 5.18667 5.39334 5.98C5.42668 6.07333 5.42001 6.20667 5.31334 6.31333L4.00001 7.62C5.10001 9.77333 6.71334 11.3867 8.87334 12.5L10.1733 11.1867C10.2667 11.0933 10.3933 11.0667 10.5133 11.1C11.3133 11.3667 12.1467 11.5 13 11.5C13.0884 11.5 13.1732 11.5351 13.2357 11.5976C13.2982 11.6601 13.3333 11.7449 13.3333 11.8333V14.1667C13.3333 14.2551 13.2982 14.3399 13.2357 14.4024C13.1732 14.4649 13.0884 14.5 13 14.5C6.93334 14.5 2.00001 9.56667 2.00001 3.5C2.00001 3.41159 2.03513 3.32681 2.09764 3.2643C2.16015 3.20179 2.24494 3.16667 2.33334 3.16667Z"
                fill="#4A4A4A"
              />
            </svg>
            {companyContactNumber}
          </div>
        </div>
      ) : null}
      {hasBio ? <p className={css.bio}>{bioWithLinks}</p> : null}

      {userType === 'corporate' ? (
        <div className={css.companyInfoContent}>
          <div style={{ fontWeight: 'bold', padding: '12px 0px' }}>
            <FormattedMessage id="ProfilePage.contactInfo" />
          </div>
          <div style={{ fontWeight: '700' }}>{name}</div>
          <div>{title} </div>
          <div>{contactNumber}</div>
        </div>
      ) : null}
      {displayName ? (
        <CustomUserFields
          publicData={publicData}
          metadata={metadata}
          userFieldConfig={userFieldConfig}
          intl={intl}
        />
      ) : null}
      <div className={css.userDetailsContainer}>
        {Object.keys(location).length > 0 ? (
          <p className={css.userDetails}>
            <img src={Location} alt="Location" />
            {location?.search}
          </p>
        ) : null}
        <p className={css.userDetails}>
          <img src={PaperPlane} alt="Availability" />{' '}
          {availableToTravel && availableToTravel !== '' ? (
            <FormattedMessage id="ProfilePage.travelYes" />
          ) : (
            <FormattedMessage id="ProfilePage.travelNo" />
          )}
        </p>
        <p className={css.userDetails}>
          <img src={Globe} alt="International License" />{' '}
          {internationLicense && internationLicense !== '' ? (
            <FormattedMessage id="ProfilePage.internationalLicenseYes" />
          ) : (
            <FormattedMessage id="ProfilePage.internationalLicenseNo" />
          )}
        </p>
        <p className={css.userDetails}>
          <img src={Globe} alt="Language" />
          {language && language.length > 0
            ? language.map((lang, index) => (
                <span key={index}>
                  {lang}
                  {index < language.length - 1 ? ', ' : ''}
                </span>
              ))
            : 'No languages specified'}
        </p>
      </div>

      {hasListings ? (
        <div className={listingsContainerClasses}>
          <H4 as="h2" className={css.listingsTitle}>
            <FormattedMessage id="ProfilePage.listingsTitle" values={{ count: listings.length }} />
          </H4>
          <ul className={css.listings}>
            {listings.map(l => (
              <li className={css.listing} key={l.id.uuid}>
                <ListingCard
                  listing={l}
                  showAuthorInfo={false}
                  isProfilePage
                  inquiryModalOpen={inquiryModalOpen}
                  setInquiryModalOpen={setInquiryModalOpen}
                  onManageDisableScrolling={onManageDisableScrolling}
                  onSendInquiry={onSendInquiry}
                  getListing={getListing}
                  sendInquiryInProgress={sendInquiryInProgress}
                  sendInquiryError={sendInquiryError}
                  onSubmit={values => {
                    const { slug, id, title, authorName } = values;
                    setParams({ slug: slug, id: id });
                    setListingTitle(title);
                    setAuthorName(authorName);
                  }}
                  currentUser={currentUser}
                />
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      <div className={css.availabilityContainer}>
        <H4 as="h2" className={css.listingsTitle}>
          <FormattedMessage id="ProfilePage.availability" />
        </H4>
        <CustomAvailability availabilityData={defaultAvailability} exceptions={exceptions} />
      </div>
      {hideReviews ? null : isMobileLayout ? (
        <MobileReviews reviews={reviews} queryReviewsError={queryReviewsError} />
      ) : (
        <DesktopReviews reviews={reviews} queryReviewsError={queryReviewsError} />
      )}

      {/* {isProfilePage ? ( */}
      <Modal
        id="ListingPage.inquiry"
        contentClassName={css.inquiryModalContent}
        isOpen={inquiryModalOpen}
        onClose={() => setInquiryModalOpen(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <InquiryForm
          className={css.inquiryForm}
          submitButtonWrapperClassName={css.inquirySubmitButtonWrapper}
          listingTitle={listingTitle}
          authorDisplayName={authorName}
          sendInquiryError={sendInquiryError}
          onSubmit={onSubmitInquiry}
          inProgress={sendInquiryInProgress}
        />
      </Modal>
      {/* ) : null} */}
    </div>
  );
};

export const ProfilePageComponent = props => {
  const config = useConfiguration();
  const intl = useIntl();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const {
    scrollingDisabled,
    params: pathParams,
    currentUser,
    useCurrentUser,
    userShowError,
    user,
    ...rest
  } = props;
  const isVariant = pathParams.variant?.length > 0;
  const isPreview = isVariant && pathParams.variant === PROFILE_PAGE_PENDING_APPROVAL_VARIANT;

  // Stripe's onboarding needs a business URL for each seller, but the profile page can be
  // too empty for the provider at the time they are creating their first listing.
  // To remedy the situation, we redirect Stripe's crawler to the landing page of the marketplace.
  // TODO: When there's more content on the profile page, we should consider by-passing this redirection.
  const searchParams = rest?.location?.search;
  const isStorefront = searchParams
    ? new URLSearchParams(searchParams)?.get('mode') === 'storefront'
    : false;
  if (isStorefront) {
    return <NamedRedirect name="LandingPage" />;
  }

  const isCurrentUser = currentUser?.id && currentUser?.id?.uuid === pathParams.id;
  const profileUser = useCurrentUser ? currentUser : user;
  const { bio, displayName, publicData, metadata } = profileUser?.attributes?.profile || {};
  const { userFields } = config.user;
  const isPrivateMarketplace = config.accessControl.marketplace.private === true;
  const isUnauthorizedUser = currentUser && !isUserAuthorized(currentUser);
  const isUnauthorizedOnPrivateMarketplace = isPrivateMarketplace && isUnauthorizedUser;
  const hasUserPendingApprovalError = isErrorUserPendingApproval(userShowError);
  const hasNoViewingRightsUser = currentUser && !hasPermissionToViewData(currentUser);
  const hasNoViewingRightsOnPrivateMarketplace = isPrivateMarketplace && hasNoViewingRightsUser;

  const isDataLoaded = isPreview
    ? currentUser != null || userShowError != null
    : hasNoViewingRightsOnPrivateMarketplace
    ? currentUser != null || userShowError != null
    : user != null || userShowError != null;

  const schemaTitleVars = { name: displayName, marketplaceName: config.marketplaceName };
  const schemaTitle = intl.formatMessage({ id: 'ProfilePage.schemaTitle' }, schemaTitleVars);

  if (!isDataLoaded) {
    return null;
  } else if (!isPreview && isNotFoundError(userShowError)) {
    return <NotFoundPage staticContext={props.staticContext} />;
  } else if (!isPreview && (isUnauthorizedOnPrivateMarketplace || hasUserPendingApprovalError)) {
    return (
      <NamedRedirect
        name="NoAccessPage"
        params={{ missingAccessRight: NO_ACCESS_PAGE_USER_PENDING_APPROVAL }}
      />
    );
  } else if (
    (!isPreview && hasNoViewingRightsOnPrivateMarketplace && !isCurrentUser) ||
    isErrorNoViewingPermission(userShowError)
  ) {
    // Someone without viewing rights on a private marketplace is trying to
    // view a profile page that is not their own – redirect to NoAccessPage
    return (
      <NamedRedirect
        name="NoAccessPage"
        params={{ missingAccessRight: NO_ACCESS_PAGE_VIEW_LISTINGS }}
      />
    );
  } else if (!isPreview && isForbiddenError(userShowError)) {
    // This can happen if private marketplace mode is active, but it's not reflected through asset yet.
    return (
      <NamedRedirect
        name="SignupPage"
        state={{ from: `${location.pathname}${location.search}${location.hash}` }}
      />
    );
  } else if (isPreview && mounted && !isCurrentUser) {
    // Someone is manipulating the URL, redirect to current user's profile page.
    return isCurrentUser === false ? (
      <NamedRedirect name="ProfilePage" params={{ id: currentUser?.id?.uuid }} />
    ) : null;
  } else if ((isPreview || isPrivateMarketplace) && !mounted) {
    // This preview of the profile page is not rendered on server-side
    // and the first pass on client-side should render the same UI.
    return null;
  }
  // This is rendering normal profile page (not preview for pending-approval)
  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ProfilePage',
        name: schemaTitle,
      }}
    >
      <LayoutSideNavigation
        sideNavClassName={css.aside}
        topbar={<TopbarContainer />}
        sideNav={
          <AsideContent
            user={profileUser}
            showLinkToProfileSettingsPage={mounted && isCurrentUser}
            displayName={displayName}
          />
        }
        footer={<FooterContainer />}
      >
        <MainContent
          bio={bio}
          displayName={displayName}
          userShowError={userShowError}
          publicData={publicData}
          metadata={metadata}
          userFieldConfig={userFields}
          hideReviews={hasNoViewingRightsOnPrivateMarketplace}
          intl={intl}
          currentUser={currentUser}
          {...rest}
        />
      </LayoutSideNavigation>
    </Page>
  );
};

ProfilePageComponent.defaultProps = {
  currentUser: null,
  user: null,
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
};

ProfilePageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUser: propTypes.currentUser,
  useCurrentUser: bool.isRequired,
  user: oneOfType([propTypes.user, propTypes.currentUser]),
  userShowError: propTypes.error,
  queryListingsError: propTypes.error,
  listings: arrayOf(oneOfType([propTypes.listing, propTypes.ownListing])).isRequired,
  reviews: arrayOf(propTypes.review),
  queryReviewsError: propTypes.error,
  onManageDisableScrolling: func,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const {
    userId,
    userShowError,
    queryListingsError,
    userListingRefs,
    reviews,
    queryReviewsError,
    sendInquiryInProgress,
    sendInquiryError,
  } = state.ProfilePage;
  const userMatches = getMarketplaceEntities(state, [{ type: 'user', id: userId }]);
  const user = userMatches.length === 1 ? userMatches[0] : null;

  // Show currentUser's data if it's not approved yet
  const isCurrentUser = userId?.uuid === currentUser?.id?.uuid;
  const useCurrentUser =
    isCurrentUser && !(isUserAuthorized(currentUser) && hasPermissionToViewData(currentUser));

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    useCurrentUser,
    user,
    userShowError,
    queryListingsError,
    listings: getMarketplaceEntities(state, userListingRefs),
    reviews,
    queryReviewsError,
    getListing,
    sendInquiryInProgress,
    sendInquiryError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSendInquiry: (listing, message) => dispatch(sendInquiryinProfilePage(listing, message)),
});

const ProfilePage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ProfilePageComponent);

export default ProfilePage;
