import React from 'react';
import { LayoutSingleColumn, Page } from '../../components';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import FooterContainer from '../FooterContainer/FooterContainer';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import CustomAboutUs from './CustomAboutUs/CustomAboutUs';
import CustomBuildYourEvent from './CustomBuildYourEvent/CustomBuildYourEvent';
import CustomEasyProcesses from './CustomEasyProcesses/CustomEasyProcesses';
import CustomFeatures from './CustomFeatures/CustomFeatures';
import CustomHero from './CustomHero/CustomHero';
import CustomJoinTheMasters from './CustomJoinTheMasters/CustomJoinTheMasters';
import CustomOurServices from './CustomOurServices/CustomOurServices';
import CustomPricing from './CustomPricing/CustomPricing';
import CustomServices from './CustomServices/CustomServices';
import { compose } from 'redux';
import { connect } from 'react-redux';

const CustomLandingPageComponent = props => {
  const {
    scrollingDisabled,
    onManageDisableScrolling,
    searchInProgress,
    searchListingsError,
  } = props;

  return (
    <Page scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn topbar={<TopbarContainer />} footer={<FooterContainer />}>
        <CustomHero />
        <CustomOurServices />
        <CustomFeatures />
        <CustomAboutUs />
        <CustomBuildYourEvent />
        <CustomServices />
        <CustomEasyProcesses />
        <CustomPricing />
        <CustomJoinTheMasters />
      </LayoutSingleColumn>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const CustomLandingPage = compose(connect(mapStateToProps))(CustomLandingPageComponent);

export default CustomLandingPage;
